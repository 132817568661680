/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Una semana ha pasado ya desde que estábamos en pleno Somero, disfrutando de charlas, entrevistas, un micro-hackaton de GNU social, talleres y lo mejor de todo, las conversaciones y la buena compañía. La pequeña experiencia en la casa comunitaria que organizamos para el evento ", React.createElement(_components.a, {
    href: "http://laenredadera.net/caos-armonico/"
  }, "la cuenta mejor que nadie Gustavo"), ", ", React.createElement(_components.a, {
    href: "http://makerslugo.org/resumo-somero2015/"
  }, "el grueso del Somero"), " está ", React.createElement(_components.a, {
    href: "http://nosolosoftware.com/somero-2015/"
  }, "perfectamente explicado por Andrés"), ", otro compañero del piso. También tenemos una mirada ", React.createElement(_components.a, {
    href: "http://www.shareable.net/blog/3-highlights-from-somero-2015-in-gijon-spain"
  }, "relatada desde más allá del charco por Neil"), " ”", React.createElement(_components.a, {
    href: "http://economia.elpais.com/economia/2015/10/05/actualidad/1444033690_724237.html"
  }, "el padre de la Sharing Economy"), "”, tirando de traductor también he leído ", React.createElement(_components.a, {
    href: "http://www.magdalenanordin.se/blog/?p=17459"
  }, "los comentarios de Mikael"), " desarrollador de GNU social. ", React.createElement(_components.a, {
    href: "https://lasindias.com/un-ano-avanzando-hacia-un-nuevo-mundo"
  }, "No podía faltar David"), ", ", React.createElement(_components.a, {
    href: "https://lasindias.com/el-patron-de-los-cambios-que-vienen"
  }, "uno de los organizadores del evento"), ". Estoy seguro de que me dejo posts en esta pequeña recopilación y que otros irán apareciendo en los próximos días."), "\n", React.createElement(_components.p, null, "Y no me voy a ir sin anunciar lo mejor que ha dejado este Somero, el nacimiento de un ", React.createElement(_components.a, {
    href: "https://entramado.net/"
  }, "Club"), ", la ampliación por parte de los Indianos de su identidad ha debido ser un gran paso para ellos, pero por otra parte ha sido algo gradual, que pasaba mientras se formaba una conversación entre la gente de laMatriz y se construía un conjunto de ideas en común, un marco para transformar el mundo, el marco de esa ventana que fue Somero 2015 a través del que empezamos a ver los nuevos mundos."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
